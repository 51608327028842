(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ClientEditRequestModel', function () {

        function ClientEditRequestModel() {
            this.addToDatabaseAutomatically = "automatically";
            this.addToDatabaseDelayed = "delayed";
            this.addToDatabaseManually = "manually";
            this.addToDatabaseWithout = "without";

            this.basicData = {
                id: null,
                name: null,
                adminFirstName: null,
                adminLastName: null,
                adminEmail: null,
                countryId: null,
                clientType: {},
                instance: null,
                clieTestClient: null,
                clieLang: null
            };
            this.codeAccount = {
                currencies: [],
                payments: {},
                codeTypePrices: []
            };
            this.isDatabaseExchangeProgramMember = false;
            this.clientIsHidden = false;
            this.findersConfiguration = {};
            this.asapContract = {
                id: null,
                    dateFrom: null,
                    dateTo: null,
                    installationType: null,
                    integrationType: null
            };
            this.contract = {
                id: null,
                contType: null,
                contDateFrom: null,
                contDateTo: null,
                contNumOfPlagReports: null,
                contNumOfPlagReportsLeft: null,
                contMaxDocPlagSize: null,
            };
            this.configuration = {
                reportSettings: {
                    ignoringBibliography: false,
                    ignoringCitations: false,
                    smartmarksDisabled: false,
                    showReversedSimilarityFactor: false,
                    addToDatabaseDays: 0
                },
                addToDatabase: this.addToDatabaseManually,
                jsaOrppdSettings: {
                    jsaIntegration: false,
                    orppdIntegration: false
                },
                userSettings: {
                    allowEditDocument: false,
                    permUserCantSeeTheDocumentsOfOthers: false,
                    permUserCanChangeOrganisationalUnit: false,
                    userTeacherCanChangePromoter: false,
                    permUsersCanDownloadOriginalFile: false,
                },
                userGroupsSettings: {
                    allowCreateStudents: false
                },
                documentFieldSettings: {
                    indexNumberPresent: false,
                    indexNumberRequired: false,
                },
                addToDatabaseSettings: {
                    teacherCantDeleteFromDatabase: true,    
                },
                otherSettings: {
                    permUserDocumentVerification: false,
                    permUserCheckDocumentMetricSimilarityBeforeAdd: false,
                    permUserDownloadPdfReport: false,
                    reservedEmails: false,
                    reservedEmailsDomainList: [],
                    acceptRegulationsEnabled: false,
                    regulationsUrl: null,
                    passwordChange: false,
                    passwordChangePeriod: null,
                    sendAlertToPromoter: false,
                    sendAlertToStudent: false,
                    permUserDoNotUseCopyPaste: false,
                    permUserUploadSupport: false,
                    authorsSimplifiedFields: true,
                    protocolsEnabled: false,
                    documentImportEnabled: false,
                    documentComparision: false,
                    assignmentsEnabled: false,
                    assignmentsPaidCorrectionsEnabled: false,
                    noDocumentUploadForStudents: false,
                },
                uploadPermissions: {
                    txtSupport: false,
                    pdfSupport: false,
                    docSupport: false,
                    docxSupport: false,
                    odtSupport: false,
                    rtfSupport: false,
                    pptSupport: false,
                    pptxSupport: false,
                    htmlSupport: false
                },
                similarityReportSettings: {
                    
                },
                grammarCheckSettings: {
                    enabled: false
                },
                aiDetectionSettings: {
                    forStudent: 'alwaysDisabled',
                    forOperator: 'alwaysDisabled',
                    forLecturer: 'alwaysDisabled',
                    forAdmin: 'alwaysDisabled',
                    forApi: 'alwaysDisabled',
                    aiDetectionHideSlider: false,
                    aiDetectionThreshold: 80,
                }
            };

            this.parseAllDates = function () {
                if(this.contract) {
                    this.contract.contDateFrom = new Date(
                        this.contract.contDateFrom);
                    this.contract.contDateTo = new Date(
                        this.contract.contDateTo);
                }
                if(this.forthcomingContract) {
                    this.forthcomingContract.contDateFrom = new Date(this.forthcomingContract.contDateFrom);
                    this.forthcomingContract.contDateTo = new Date(this.forthcomingContract.contDateTo);
                }
                if (this.asapContract) {
                    this.asapContract.dateFrom = new Date(this.asapContract.dateFrom);
                    this.asapContract.dateTo = new Date(this.asapContract.dateTo);
                }
            }
        }
        return ClientEditRequestModel;
    });

})();
